/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

const alignMap = {
  Left: 'start',
  Center: 'center',
  Right: 'end'
};

export const myStyles = makeStyles((theme) => ({
  root: () => ({
    flexGrow: 1,
    '& #superText': {
      fontFamily: `${theme.typography.h1.fontFamily}`,
      fontSize: '16px !important',
      fontWeight: '400'
    }
  }),
  paper: (data) => ({
    color: theme.palette.text.primary,
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }),
  mainImage: (data) => ({
    maxWidth: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    height: data?.imageMaxHeight || '100%'
  }),
  svgImage: (data) => ({
    maxWidth: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    height: data?.imageMaxHeight || '100%'
  }),
  imageWrapper: (data) => ({
    height: data?.imageMaxHeight || '100%'
  }),
  titleHeading: (data) => ({
    color: data?.textColor ? data.textColor : '#000000'
  }),
  hr: () => ({
    width: '80px',
    height: '8px',
    border: 'none',
    borderRadius: '0',
    [theme.breakpoints.up('xs')]: {
      margin: '0 auto'
    },
    [theme.breakpoints.up('md')]: {
      margin: '0'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0'
    }
  }),
  p: (data) => ({
    color: data?.textColor ? data.textColor : 'inherit'
  }),
  paddingLeft: () => ({

    [theme.breakpoints.up('xs')]: {
      paddingLeft: '0'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '15%'
    }
  }),
  imageGrid: () => ({
    display: 'table'
  }),
  customBtnAlignment: () => ({
    '& button': {
      margin: '0',
      marginTop: '20px',
      marginBottom:'10px'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center !important',
      justifyContent: 'center !important',
      direction: 'column',
      '& button': {
        width: '94vw'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& button': {
        width: '92vw'
      }
    },
    '& #image-with-text-cta-link': {
      borderBottom: 'none !important'
    }
  })
}));

export const imageStyles = makeStyles((theme) => ({
  newContainer: (data) => ({
    paddingLeft: `${data?.paddingLeft}px` || '0px',
    paddingRight: `${data?.paddingRight}px` || '0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 20px 0px'
    }
  }),
  imageWrapper: (data) => ({
    height: data?.imageMaxHeight ? data.imageMaxHeight : '100%'
  }),
  titleHeading: (data) => ({
    color: data?.textColor ? data.textColor : '#000000'
  }),
  p: (data) => ({
    '& p:nth-child(1)': {
      marginBottom: 0
    },
    '& p:nth-child(2)': {
      marginTop: 0
    },
    [theme.breakpoints.only('md')]: {
      '& h3': {
        width: '187px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block'
      },
      '& h3:hover': {
        textOverflow: 'clip',
        whiteSpace: 'inherit',
        wordBreak: 'break-all'
      }
    },
    color: data?.textColor ? data.textColor : 'inherit',
    '& p:nth-of-type(2)': {
      paddingTop: data?.isCard && '10%'
    },
    '& a': {
      color: data?.linkTextColor || '#464749',
      borderBottom: data?.hasAnchorLinkWithDottedUnderline && '1px dotted',
      position: data?.isCard && 'absolute',
      display: data?.isCard && 'block',
      left: data?.isCard && '0',
      right: data?.isCard && '0',
      bottom: data?.isCard && '25px',
      width: data?.isCard && 'fit-content',
      margin: data?.isCard && '0 auto'
    }
  }),
  underline: (data) => ({
    display: 'grid',
    width: '100%',
    justifyContent: alignMap[data.alignText] || 'flex-start'
  }),
  paperAlignText: (data) => ({
    textAlign: alignMap[data?.alignText] || 'center'
  })
}));
