/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const footerStyles = makeStyles((theme) => ({
  footerContainer: (data) => ({
    background: '#f0f0f0 ',
    maxWidth: '1936px',
    padding: '0px !important',
    margin: '0 auto',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px !important',
      '& p': {
        textAlign: 'left'
      }
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '60px !important'
    },
    '& p': {
      padding: '0',
      margin: '0',
      color: data?.typography?.footerTextColor || '#000000'
    },
    '& div:first-of-type': {
      // maxWidth: '100%'
    }
  })

}));
