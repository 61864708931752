/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { footerStyles } from './styles';
import ThemeContext from '../../utils/theme-context';

export default function FooterLinks({ data }) {
  const partnerTheme = useContext(ThemeContext);
  const classes = footerStyles(partnerTheme);
  const [privacyUrl, setPrivacyUrl] = useState('');
  // const script1 = `https://consent.trustarc.com/notice?domain=realogyleadsgroup.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&privacypolicylink=${privacyUrl}`;

  useEffect(() => {
    const getUrl = async () => {
      const url = await JSON.parse(localStorage.getItem('privacyUrl'));
      const encodedUrl = encodeURIComponent(url);
      setPrivacyUrl(encodedUrl);
    };
    getUrl();
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://consent.trustarc.com/notice?domain=realogyleadsgroup.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&privacypolicylink=${privacyUrl}`;
    script.async = true;
    document.body.appendChild(script);
  }, [])

  return (
    <>
      <Container id="footer-links-space" maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            className={classes.footerLinks}
          >
            {data.links.map((link) => {
              const isNewTab = link.link.openInANewTab ? 'blank' : '';
              return (
                <Grid id={`footer-link-${link.label}`} item key={link.link.id}>
                  <Link aria-label={link.label} to={!link.link.openInANewTab ? `/${link.link.referenceToPage?.pageName}` : `${link.link.url}`} target={isNewTab} className={classes.links}>{link.label}</Link>
                </Grid>
              );
            })}
            <span id="teconsent" className={classes.cookieLink} />
            {/* <Helmet> */}
            {/* <script type="text/javascript" async="async" dangerouslySetInnerHTML={{ __html: script1 }} crossOrigin="" /> */}
            {/* <script async="async" src={`https://consent.trustarc.com/notice?domain=realogyleadsgroup.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&privacypolicylink=${privacyUrl}`} crossOrigin="" /> */}
            {/* </Helmet> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
