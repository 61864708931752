/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    flexGrow: 1,
    textAlign: 'center',
    color: props?.textColor ? props.textColor : '#000',
    backgroundColor: props?.backgroundColor ? props.backgroundColor : 'inherit'
  }),
  gridWrapper: () => ({
    [theme.breakpoints.up('lg')]: {
      padding: '80px 140px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '80px 140px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '80px 140px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '35px 30px'
    }
  }),
  signupButton: () => ({
    margin: '30px auto 10px !important',
    borderBottom: 'none !important'
  }),
  cashbackRewardSignupBtn: () => ({
    borderBottom: 'none !important'
  })
}));
