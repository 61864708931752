/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import { Container, Typography, Button } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import clsx from 'clsx';
import { myStyles, imageStyles } from './styles';
import ThemeContext from '../../utils/theme-context';
import { themeStyles } from '../../styles';
import superScriptFormat from '../../utils/superscript-check';

export default function ImageWithText({ data }) {
  const url = data?.image?.file?.url?.split('.').pop();
  const imgUrl = url?.split(/[#?]/)[0].split('.').pop().trim();
  const imageClasses = imageStyles(data);
  const partnerTheme = useContext(ThemeContext);
  const [ast, setAst] = useState(false);
  const [flowOrder, setFlowOrder] = useState('');
  const classes = myStyles(partnerTheme);
  const globalthemClasses = themeStyles(partnerTheme);
  let showModal;
  const alignMap = {
    Left: 'flex-start',
    Center: 'center',
    Right: 'flex-end'

  };
  useEffect(() => {
    setAst(JSON.parse(localStorage.getItem('ast')));
    setFlowOrder(JSON.parse(localStorage.getItem('flowOrder')));
  });

  const variantVal = partnerTheme?.buttonStyle?.buttonType === 'contained' || partnerTheme?.buttonStyle?.buttonType === 'outlined' || partnerTheme?.buttonStyle?.buttonType === 'text' ? partnerTheme?.buttonStyle?.buttonType : 'contained';
  let btnClassName;
  if (variantVal === 'contained') {
    btnClassName = globalthemClasses.containedBtn;
  } else if (variantVal === 'outlined') {
    btnClassName = globalthemClasses.outlinedBtn;
  } else {
    btnClassName = globalthemClasses.textBtn;
  }

  if (ast) {
    showModal = '/ast';
  } else {
    switch (flowOrder) {
      case 'Selling Only':
        showModal = '/customer-info';
        break;
      case 'Buying Only':
        showModal = '/customer-info';
        break;
      case 'Buying, Selling':
        showModal = '/service-selection';
        break;
      case 'Selling, Buying':
        showModal = '/service-selection';
        break;
      default:
        showModal = '/';
    }
  }

  return (
    <>
      <Container id="cmp-image-with-text" className={imageClasses.newContainer}>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs>
              <Paper elevation={0} className={clsx(classes.paper, imageClasses.paperAlignText)}>
                {data.image && (
                  <div className={imageClasses.imageWrapper}>
                    {imgUrl !== undefined && imgUrl === 'svg'
                      ? (
                        <svg
                          id="image-with-text-svg-img"
                          data-src={data.image.file.url}
                          className={clsx(globalthemClasses.svgColor, classes.svgImage)}
                        />
                      )
                      : (
                        <img
                          id="image-with-text-image"
                          className={classes.mainImage}
                          src={data.image.file.url}
                          alt={data.image.description}
                        />
                      )}
                  </div>
                )}
                {data.titleText && (
                <Typography id="image-with-text-h2-text" variant="h2" className={imageClasses.titleHeading}>
                  {superScriptFormat(data.titleText)}
                </Typography>
                )}
                {data.titleUnderline && (
                  <div className={imageClasses.underline}>
                    <hr className={clsx(globalthemClasses.progressBarColor, classes.hr)} />
                  </div>
                )}
                {data?.subText?.raw && (
                  <Typography id="image-with-text-para-text" variant="body2" className={clsx(classes.p, globalthemClasses.imageWithTextLink, imageClasses.p)}>
                    {documentToReactComponents(JSON.parse(data.subText.raw))}
                  </Typography>
                )}
              </Paper>
            </Grid>
            {data.isCtaButtonEnabled && (
            <Grid
              container
              direction="column"
              justifyContent={alignMap[data?.alignText] || 'flex-start'}
              alignItems={{ xs: 'center', sm: alignMap[data?.alignText] || 'flex-start' }}
              className={classes.customBtnAlignment}
            >
              <Link id="image-with-text-cta-link" className={classes.ctaLink} to={showModal} tabindex="-1">
                <Button
                  id="image-with-text-btn"
                  variant={variantVal}
                  className={`${btnClassName}`}
                  size="large"
                  role="dialog"
                  aria-modal="true"
                >
                  {data.button.labelForPrimaryCta}
                </Button>
              </Link>
            </Grid>
            )}
          </Grid>
        </div>
      </Container>
    </>
  );
}
